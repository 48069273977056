import { ActivatedRoute, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

export interface NavigationConfig {
  step: number;
  part: number;
  totalSteps: number;
  nextLink?: string;
  prevLink?: string;
  finish?: boolean;
}

export class NavigationBaseClass {
  protected router: Router;
  public config: NavigationConfig;
  public evaluationID: string;

  constructor(router: Router, route: ActivatedRoute) {
    this.config = route.snapshot.data.config;
    this.router = router;

    route.queryParams
      .pipe(
        filter((params) => params.id),
        map((params) => params.id)
      )
      .subscribe((id) => {
        this.evaluationID = id;
      });
  }

  public navigate(url: string): void {
    this.router.navigateByUrl(url);
  }
}
