export const mapStepBody = (object): { QuestionID; Answer }[] => {
  const answers = [];

  for (const [key, value] of Object.entries(object)) {
    answers.push({
      QuestionID: key,
      Answer: value,
    });
  }
  return answers;
};
